<template>
  <div>
    <!-- SECTION 1 -->
    <section class="section">
      <CContainer class="">
        <CRow>
          <CCol sm="12" class="text-center block-header">
            <h1>{{ project.name }}</h1>
            <p class="text-muted">
              <CIcon name="cil-map" />
              {{ project.location }}
            </p>
            <br />
          </CCol>
          <CCol sm="12">
            <CImg
              v-if="project.logo"
              :src="storageURL + project.logo"
              width="400"
              block
              class="mb-2 logo-img"
            />
            <CImg
              v-else
              src="/img/frontpage/image-placeholder.jpg"
              width="400"
              block
              class="mb-2 logo-img"
            />
          </CCol>

          <CCol sm="12" class="project-detail-block">
            <div v-html="project.description"></div>
          </CCol>

          <CRow class="images-list">
            <CCol
              xs="4"
              class="list-image"
              v-for="image in project.images"
              v-bind:key="image.id"
            >
              <CImg
                :src="storageURL + image.file_name"
                width="400"
                block
                class="mb-2 project-img"
              />
            </CCol>
          </CRow>
        </CRow>
      </CContainer>
    </section>
  </div>
</template>

<script>
import ProjectAPI from "/app/src/api/project.js";

export default {
  name: "CaseDetail",
  data: () => {
    return {
      projectAPI: new ProjectAPI(),
      project: {},
      storageURL: process.env.VUE_APP_STORAGE_URL,
    };
  },

  created: function () {
    this.projectAPI
      .detail(this.$route.params.id)
      .then((project) => {
        this.project = project;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {},
};
</script>

<style scoped>
.project-detail-block {
  margin-top: 50px;
  margin-bottom: 50px;
}

.block-header svg {
  margin-top: -4px;
}

.logo-img {
  margin: 0 auto;
  border-radius: 15px;
}

.project-img {
  margin: 15px;
  border-radius: 15px;
}

.images-list {
  margin: 0 auto;
}
</style>
